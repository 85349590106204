.testimonials {
  padding: 50px 0;
  text-align: center;
}

.testimonials h2 {
  @apply text-3xl font-bold;
  margin-bottom: 20px;
  color: #1E3A8A; /* Dark Blue */
}

.testimonial-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.testimonial {
  max-width: 400px;
  margin: 10px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.testimonial h3 {
  @apply text-xl font-semibold;
  margin-bottom: 10px;
  color: #1E3A8A; /* Dark Blue */
}

.testimonial .location {
  font-size: 0.9rem;
  color: #555;
}

.testimonial p {
  @apply text-sm leading-relaxed;
  color: #4A5568; /* Dark Gray */
}
