.header {
  background-color: #FFC107; /* Sunflower */
  color: #1E3A8A; /* Dark Blue */
  padding: 1rem;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
}

.nav-container {
  @apply flex items-center justify-between max-w-screen-xl mx-auto px-4;
}

.logo {
  height: 80px; /* Adjust the height as needed */
  width: auto;  /* Maintain aspect ratio */
  margin-left: 60px;
}

.hamburger {
  display: none; /* Hide hamburger by default */
  flex-direction: column;
  cursor: pointer;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #1E3A8A; /* Dark Blue */
  margin: 4px 0;
  transition: all 0.3s ease;
}

.open.line1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.open.line2 {
  opacity: 0;
}

.open.line3 {
  transform: rotate(-45deg) translate(10px, -10px);
}

.nav-list {
  @apply flex space-x-4;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  @apply cursor-pointer hover:text-secondary; /* Dark Blue */
}

@media (max-width: 768px) {
  .hamburger {
    display: flex; /* Display the hamburger on small screens */
  }

  .nav-list {
    flex-direction: column;
    align-items: center;
    background-color: #FFC107; /* Sunflower */
    position: absolute;
    top: 56px;
    left: 0;
    right: 0;
    width: 100%;
    display: none;
  }

  .nav-list.open {
    display: flex;
  }

  .nav-item {
    text-align: center;
    padding: 1rem;
    margin: 0.5rem 0;
    font-size: 1.25rem;
    cursor: pointer;
    color: #1E3A8A; /* Dark Blue */
  }
}
