.services {
  padding: 40px 0;
  text-align: center;
}

.services h2 {
  @apply text-3xl font-bold;
  margin-bottom: 20px;
  color: #1E3A8A; /* Dark Blue */
}

.services-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.arrow {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  z-index: 1;
}

.arrow.left {
  left: 10px;
}

.arrow.right {
  right: 10px;
}

.services-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  gap: 20px; /* Add some gap between cards */
  padding: 15px 40px; /* Add some padding to the container */
}

.service {
  width: 250px; /* Set the width */
  height: 250px; /* Set the height to match the width */
  flex: 0 0 auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center; /* Center text alignment */
}

.service h3 {
  @apply text-lg font-semibold; /* Adjust text size for smaller cards */
  margin-bottom: 10px;
  color: #1E3A8A; /* Dark Blue */
}

.service p {
  @apply text-sm leading-relaxed;
  margin: 0; /* Ensure no extra margin around text */
  color: #4A5568; /* Dark Gray */
}
