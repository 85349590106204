.about-container {
  @apply grid grid-cols-1 md:grid-cols-2 gap-8 p-10;
}

.card {
  @apply bg-white shadow-lg rounded-lg p-6;
  width: 100%; /* Full width for small screens */
  max-width: 500px; /* Ensure it doesn't get too wide on larger screens */
  margin: 0 auto; /* Center the card */
}

.card-icon {
  width: 50px; /* Set a specific width for the icons */
  height: 50px; /* Set a specific height for the icons */
  @apply mb-4;
}

.card-title {
  @apply text-2xl font-bold mb-2;
  color: #1E3A8A; /* Dark Blue */
}

.card-content {
  @apply text-lg leading-relaxed;
  color: #4A5568; /* Dark Gray */
}

@media (min-width: 768px) {
  .card {
    width: 90%; /* Adjust width for medium screens and above */
  }
}

@media (max-width: 767px) {
  .card {
    width: 95%; /* Adjust width for small screens */
  }
}
